@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Light.eot");
  src: local("Nunito Light"), local("Nunito-Light"),
    url("./assets/fonts/Nunito-Light.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Nunito-Light.woff") format("woff"),
    url("./assets/fonts/Nunito-Light.ttf") format("truetype"),
    url("./assets/fonts/Nunito-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Regular.eot");
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url("./assets/fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Nunito-Regular.woff") format("woff"),
    url("./assets/fonts/Nunito-Regular.ttf") format("truetype"),
    url("./assets/fonts/Nunito-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-SemiBold.eot");
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url("./assets/fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Nunito-SemiBold.woff") format("woff"),
    url("./assets/fonts/Nunito-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Nunito-SemiBold.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Bold.eot");
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url("./assets/fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Nunito-Bold.woff") format("woff"),
    url("./assets/fonts/Nunito-Bold.ttf") format("truetype"),
    url("./assets/fonts/Nunito-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Arimo";
  src: url("./assets/fonts/Arimo-Bold.ttf");
  src: local("Arimo Bold"), local("Arimo-Bold"),
    url("./assets/fonts/Arimo-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Forum";
  src: url("./assets/fonts/Forum-Regular.ttf");
  src: local("Forum Regular"), local("Forum-Regular"),
    url("./assets/fonts/Forum-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 5px;

  .card {
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: 5px;
    justify-content: center;
    align-items: center;
  }
  .card body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.cardbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
}

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  .card {
    display: flex;
    flex: 0 0 auto;
    margin: 5px;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 900px) {
  .scrolling-wrapper-flexbox {
    justify-content: center; /* Center flex items on the main-axis */
    margin-left: auto;
    margin-right: auto;
  }
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper {
  &::-webkit-scrollbar {
    display: none;
  }
}

.stickyHeaderNew {
  position: sticky;
  top: 0;
  z-index: 100;
}
